<template>
    <div class="app-header bg-[#222222]">
        <layout-contained>
            <div class="pt-2 pb-3 text-2xl">
                <nuxt-link :aria-label="$config.public.siteName" to="/">
                    <picture>
                        <source :srcset="$config.public.images + $config.public.siteLogoAvif" type="image/avif" media />
                        <source :srcset="$config.public.images + $config.public.siteLogoWebp" type="image/webp" media />
                        <img :src="$config.public.images + $config.public.siteLogo" width="100" height="61" alt />
                    </picture>
                </nuxt-link>
            </div>
        </layout-contained>
    </div>
</template>

<script>
export default {
    name: 'AppHeader',
};
</script>
