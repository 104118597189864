<template>
    <div class="app flex flex-col h-screen">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'App',
}
</script>
