<template>
    <div class="app-body mb-auto">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'AppBody',
};
</script>
