<template>
    <div class="app-footer bg-zinc-800">
        <layout-contained>
            <div class="py-6">
                <span class="text-gray-100">
                    &copy; {{ new Date().getFullYear() }}
                    {{ $config.public.companyNameLong }} |
                    <NuxtLink class="hover:underline" to="/">Home</NuxtLink> |
                    <NuxtLink class="hover:underline" to="/contact">Contact</NuxtLink> |
                    <NuxtLink class="hover:underline" to="/terms">Terms</NuxtLink>
                </span>
            </div>
        </layout-contained>
    </div>
</template>

<script>
export default {
    name: 'AppFooter',
};
</script>
